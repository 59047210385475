import PropTypes from 'prop-types';
import React, { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Step,
    Paper,
    Button,
    Stepper,
    StepLabel,
    Typography,
    StepConnector,
    stepConnectorClasses,
} from '@mui/material';
// utils
// components
import Iconify from './../../Iconify';
import StepOne from './step1';
import StepTwo from './step2';
import StepThree from './step3';
import StepFour from './step4';
import { initialValues, initialValuesCalc } from '../../../constants/gmc-form.const';
import axios from 'axios';


const GradientBorder = styled('div')({
    alignItems: 'center',
    background: '#fff',
    backgroundClip: 'initial',
    border: '2px solid transparent',
    borderRadius: '34px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto 47px',
    padding: '4px',
    position: 'relative',
    maxWidth: '500px',
    width: '100%',
    '&::before': {
        background: 'linear-gradient(115deg, #9d18fb, #a680e0 53%, #ffa53d 88%, #ffc700 97%)',
        borderRadius: 'inherit',
        bottom: 0,
        content: '""',
        left: 0,
        margin: '-2px',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 0,
    },
});

const STEPS = ['Step 1', 'Step 2', 'Step 3', "Step 4"];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.success.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.success.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderRadius: 1,
        borderTopWidth: 3,
        borderColor: theme.palette.divider,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    height: 22,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.disabled,
    ...(ownerState.active && {
        color: theme.palette.success.main,
    }),
    '& .QontoStepIcon-completedIcon': {
        zIndex: 1,
        fontSize: 18,
        color: theme.palette.success.main,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    className: PropTypes.string,
};

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Iconify
                    icon="fluent-mdl2:completed-solid"
                    className="QontoStepIcon-completedIcon"
                    width={40}
                    height={40}
                />
            ) : (
                <Iconify icon="vaadin:dot-circle" width={40} />
            )}
        </QontoStepIconRoot>
    );
}








export default function CustomizedSteppers() {
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState(initialValues);
    const [dataCalc, setDataCalc] = useState(initialValuesCalc);

    console.log('data===>', data);


    const makeRequest = async (formData) => {
        try{
        const response = await axios.post('https://backend-form-app.vercel.app/gmc', formData);
        console.log('Response:', response.data);
        } catch (error) {
        console.error('Error:', error);
      }
    
    };

    const handleNext = (newData) => {
        console.log('newData', newData);
        setData({ ...newData });
        setDataCalc(prev => ({ ...prev, ...newData }));
        setActiveStep((activeStep) => activeStep + 1);
        console.log("activeStep = ",activeStep)
        if(activeStep === 3){
            makeRequest(newData);
        }
    };

    // const isNumberOfLivesEqualToSum = () => {
    //     const totalNumber = Number(values.totalNumberOfEmployees) || 0;

    //     const numberOfLives = [
    //         values.employeeCount35,
    //         values.employeeCount45,
    //         values.employeeCount55,
    //         values.employeeCount65,
    //         values.spouseCount35,
    //         values.spouseCount45,
    //         values.spouseCount55,
    //         values.spouseCount65,
    //         values.parentsCount45,
    //         values.parentsCount55,
    //         values.parentsCount65,
    //         values.parentsCount66,
    //         values.totalKids,
    //     ];

    //     const sum = numberOfLives.reduce((acc, cur) => parseInt(acc) + parseInt(cur));
    //     return sum === totalNumber;
    // };

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };
    function getStepContent(step) {
        switch (step) {
            case 0:
                return <StepOne STEPS={STEPS} data={data} setData={setData} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
            case 1:
                return <StepTwo STEPS={STEPS} data={data} setData={setData} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
            case 2:
                return <StepThree STEPS={STEPS} data={data} setData={setData} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
            case 3:
                return <StepFour STEPS={STEPS} data={data} setData={setData} activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
            default:
                return 'Unknown step';
        }
    }

    const handleReset = () => {
        setActiveStep(0);
    };


    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 35,
                marginTop: 5

            }}
        >
            <Box style={{ maxWidth: 650, width: "100%" }}            >
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {STEPS.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon} />                    </Step>
                    ))}
                </Stepper>

                <Box sx={{ m: 5 }} />
                {activeStep === STEPS.length ? (
                    <GradientBorder>
                        <Paper
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '30px',
                                padding: '32px 75px',
                                width: '100%',
                                zIndex: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            <Typography sx={{ my: 1, textAlign: "center" }}>Information has been Submited.</Typography>
                            <Button variant='contained' color="error" onClick={handleReset} sx={{ mr: 1 }}>
                                Reset
                            </Button>
                        </Paper>


                    </GradientBorder>
                ) : (
                    <>
                        <GradientBorder


                        >
                            <Paper
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: '30px',
                                    padding: '32px 75px',
                                    width: '100%',
                                    zIndex: 2,
                                }}>
                                <Typography sx={{ my: 1 }}>{getStepContent(activeStep)}</Typography>

                            </Paper>
                        </GradientBorder>


                    </>
                )}
            </Box>
        </Box>
    );
}